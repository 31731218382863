import React, { useEffect, useState } from "react";

const [check, partial, uncheck] = ['fa-check-square', 'fa-minus-square', 'fa-square'];

const getSelected = (value, current, children) => {
    if (!children?.length) return current.includes(value) ? true : false;

    // si tiene todos los children
    if (children.every(item => current.includes(item))) return true;

    if(children.some(item => current.includes(item))) return null

    return false;
};
const PermisosSelection = ({label, value, children, current, add, remove}) => {
    const [collapse, setCollapse] = useState(true);
    const [selectedIcon, setSelectedIcon] = useState();
    const [selected, setSelected] = useState(getSelected(value, current, children?.map((child) => child.value)));

    const toggleCollapse = () => {
        setCollapse(!collapse);
    };

    const toggleSelection = () => {
        const val = children?.length ? [value, ...children.map((child) => child.value)] : [value];
        if (selected === true) remove(val);
        else add(val);
    };

    const toggleChildSelection = (child) => {
        const childrenSelectedCount = children?.map((c) => current.includes(c.value) ? 1 : 0).reduce((acc, num) => acc + num, 0);

        if (current.includes(child.value)) {
            remove([child.value, ...(childrenSelectedCount === 1 ? [value] : [])]);
        }
        else {
            add([...(childrenSelectedCount === 0 ? [value] : []), child.value]);
        }
    };

    useEffect(() => {
        setSelected(getSelected(value, current, children?.map((child) => child.value)));
    }, [value, current, children]);

    useEffect(() => {
        setSelectedIcon(selected === true ? check : (selected === false ? uncheck : partial));
    }, [selected]);

    return (<div className="accordion">
        <div className="card">
            <div className="card-header flex p-0">
                <i className={`far ${selectedIcon} my-auto mx-1`} style={{cursor: "pointer"}} onClick={toggleSelection} />

                <span className="my-auto ml-1 mr-auto">
                    {label}
                </span>

                {children?.length &&
                    <i className={`fa ${collapse ? 'fa-plus' : 'fa-minus'} my-auto ml-auto mr-1`} style={{cursor: "pointer"}} onClick={toggleCollapse} />
                }
            </div>

            <div className={`collapse${collapse ? '' : ' show'}`}>
                <div className="card-body px-4 py-1">
                    {children?.map((child, index) =>
                        <div className="flex" key={`child[${index}]`}>
                            <i className={`far ${current.includes(child.value) ? check : uncheck} my-auto mx-1`} style={{cursor: "pointer"}} onClick={() => toggleChildSelection(child)} />

                            <span className="my-auto ml-1 mr-auto">
                                {child.label}
                            </span>
                        </div>
                    )} 
                </div>
            </div>
        </div>
    </div>);
};

export default PermisosSelection;
