import React, { useState } from "react";
import { permisions } from "../../../utils/helpers";
import PermisosSelection from "./PermisosSelection";
import { auth } from "../../../utils/auth";

const Permisos = ({permisosUsuario, rol}) => {
    const grupo = [
        {
            label: 'Dashboard',
            value: permisions.verDashboard,
        },
        {
            label: 'Académico',
            value: permisions.verAcademico,
            children: [
                { label: 'Evaluaciones', value: permisions.verEvaluaciones},
                { label: 'Estudiantes', value: permisions.verEstudiantes},
                { label: 'Inscripción', value: permisions.verInscripcion},
                { label: 'Padres/Tutores', value: permisions.verPadrestutores},
                { label: 'Cobros Académicos', value: permisions.verFacturacionAcademica},
            ],
        },
        {
            label: 'Cocurriculares',
            value: permisions.verCocurriculares,
            children: [
                { label: 'Cobros', value: permisions.verCobros},
                { label: 'Inscripción', value: permisions.verInscripcionCocurriculares},
            ],
        },
        {
            label: 'Contabilidad',
            value: permisions.verContabilidad,
            children: [
                { label: 'Contabilidad', value: permisions.verContabilidad},
                { label: 'Bancos', value: permisions.verCuentas},
            ],
        },
        {
            label: 'Contratos',
            value: permisions.verContratos,
        },
        {
            label: 'Programación',
            value: permisions.verProgramacion,
        },
        {
            label: 'Ingresos',
            value: permisions.verIngresos,
            children: [
                { value: permisions.verFacturas, label: 'Facturas'},
                { value: permisions.verFacturasRecurrentes, label: 'Facturas Recurrentes'},
                { value: permisions.verCotizaciones, label: 'Cotizaciones'},
                { value: permisions.verOrdenesCompra, label: 'Ordenes de compra'},
            ],
        },
        {
            label: 'Consultas',
            value: permisions.verconsultas,
            children: [
                { value: permisions.verCuentasPorCobrar, label: 'Cuentas por cobrar'},
                { value: permisions.verCuentasPorPagar, label: 'Cuentas por pagar'},
                { value: permisions.verRecibosIngreso, label: 'Recibos'},
                { value: permisions.verNotasCredito, label: 'Notas de crédito'},
            ],
        },
        {
            label: 'Operaciones',
            value: permisions.verGiGrupos,
            children: [
                { label: 'Grupos', value: permisions.verGiGrupos},
                { label: 'Servidores', value: permisions.verGiServidores},
                { label: 'Prestamos', value: permisions.verGiPrestamos},
            ],
        },
        {
            label: 'Empleados',
            value: permisions.verEmpleados,
            children:
            [
                { label: 'Listado', value: permisions.verEmpleados },
                { label: 'Tipos Empleados', value: permisions.verTipos },
                { label: 'Grupos Tipos', value: permisions.verGrupoTiposEmpleados },
                { label: 'Ausencias', value: permisions.verEmpleados },
            ]
        },
        {
            label: 'Nómina',
            value: permisions.verNomina,
        },
        {
            label: 'Descuentos',
            value: permisions.verDescuentos,
        },
        {
            label: 'Incentivos',
            value: permisions.verIncentivos,
        },
        {
            label: 'Solicitudes',
            value: permisions.verSolicitudes,
        },
        {
            label: 'Reclamaciones',
            value: permisions.verReclamaciones,
        },
        {
            label: 'Vehículos',
            value: permisions.verVehiculos,
        },
        {
            label: 'Servicios',
            value: permisions.verServicios,
        },
        {
            label: 'Zonas',
            value: permisions.verRutas,
        },
        {
            label: 'Inventario',
            value: permisions.verInventario,
            children: [
                { value: permisions.verInventario, label: 'Ver Inventario'},
                { value: permisions.editarInventario, label: 'Editar Inventario'},
                { value: permisions.verProductos, label: 'Ver Productos'},
                { value: permisions.editarProductos, label: 'Editar Producto'},
                { value: permisions.verCompras, label: 'Compras'},
            ],
        },
        {
            label: 'Gastos',
            value: permisions.verGastos,
        },
        {
            label: 'Gastos Generales',
            value: permisions.verGastosGenerales,
        },
        {
            label: 'Contactos',
            value: permisions.verContactos,
        },
        {
            label: 'Configuraciones',
            value: permisions.verConfiguracion,
            children: [
                {value: permisions.editarUsuarios, label: 'Editar Usuarios'},
                {value: permisions.editarSucursales, label: 'Editar Sucursales'},
            ],
        },
    ];

    const [currentPermission, setCurrentPermission] = useState([
        ...(rol ? (rol in auth.permissions ? auth.permissions[rol] : []) : []),
        ...(permisosUsuario ?? []),
    ]);

    const addToCurrent = (permisions) => {
        setCurrentPermission([...currentPermission, ...permisions]);
    };
    const removeFromCurrent = (permisions) => {
        setCurrentPermission(currentPermission.filter((element) => !permisions.includes(element)));
    }

    return <>
        <div className="form-group row">
            <div className="col-5 py-1">
                <label style={{fontSize: '18px'}}>Permisos</label> 
            </div>

            <div className="col-7">
                {/* <input type="search" placeholder="Buscar..." className="form-control"/> */}
            </div>
        </div>

        <div className="row" style={{height: 'calc(100% - 60px)'}}>
            {currentPermission.filter((permission) => permission).map((permission, index) => <input type="hidden" name="permisos[]" value={permission} key={`permisos[${index}]`} />)}
            <div className="col" style={{height: '100%'}}>
                <div style={{width: '100%', height: '100%' }}>
                    {grupo.map((session, index) =>
                        <PermisosSelection key={`session[${index}]`} label={session.label} value={session.value} children={session.children} current={currentPermission} add={addToCurrent} remove={removeFromCurrent} />
                    )}
                </div>
            </div>
        </div>
    </>;
};

export default Permisos