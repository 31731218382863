import React, { useState } from "react";
import Form from "./Form";
import { apiUrl } from "../../../utils/apiUrl";
import { toast } from "../../../utils/helpers";
import { postRequest } from "../../../utils/request";

const Edit = ({getUsers, toggle, sucursales, usuario}) => {
    const [errors, setErrors] = useState({});

    const editUser = async event => {
        event.preventDefault();

        const data = new FormData(event.target);
        const response = await postRequest(apiUrl(`usuarios/${usuario.id}`), data);

        if (response.success) {
            getUsers();
            toggle();
            toast("Usuario Actualizado.");
        }

        if (response.code === 422) {
            setErrors(response.data.message);
        }
    };
    
    const deleteUsuario = async (event, usuario) => {
        event.preventDefault();

        const url = `usuarios/${usuario}`;

        const response = await postRequest(apiUrl(url), {}, 'delete');

        if (response.success) {
            getUsers();
            toggle();
            toast("Usuario borrado.");
            return;
        } else {
            toast(response.data, "error");
        }
    };

    return (
        <form method='post' onSubmit={editUser}>
            <input type='hidden' name='_method' value='patch' />
            <div className='popup__header'>Editar Usuario</div>
            <div className='popup__body'>
                <Form usuario={usuario} errors={errors} sucursales={sucursales} />
            </div>
            <div className='popup__footer'>
                <button
                    type='button'
                    className='btn float-left'
                    onClick={e => deleteUsuario(e, usuario.id)}>
                    <i className='fas fa-trash-alt'></i>
                </button>
                <button
                    type='button'
                    onClick={toggle}
                    className='btn btn-plain'>
                    Cancelar
                </button>
                <button type='submit' className='btn btn-primary'>
                    Guardar
                </button>
            </div>
        </form>
    );
};

export default Edit;
